import React from 'react';

const ControlsDeviceSubControls = ({
  subControlId,
  subControlClass,
  subControlUrl,
  subControlLabel,
  subControlMethod,
}) => {
  return (
    <div id={subControlId} className={`sub-controls-group ${subControlClass ? subControlClass : ''}`}>
      {
        !subControlUrl ?
          (
            <div className="sub-controls-label apply-ripple vlcm-config-key-val-contrast" data-ripple-color="#D0E8F9">
              <span>{subControlLabel}</span>
            </div>
          ) :
          (
            <a
              style={{ display: 'block' }}
              className="clearfix"
              href={subControlUrl}
              rel={subControlMethod === 'delete' ? 'nofollow' : ''}
              data-method={subControlMethod === 'delete' ? 'delete' : ''}
            >
              <div
                className="sub-controls-label vlcm-config-key-val-contrast apply-ripple"
                data-ripple-color="#D0E8F9"
              >
                <span>{subControlLabel}</span>
              </div>
            </a>
          )
      }
    </div>
  );
};

export default ControlsDeviceSubControls;
