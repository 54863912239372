import React from 'react';

import ControlsDeviceGroup from '@simonsaves-components/common/controls-device-group';

import SearchBluePng from 'AssetPipelineImages/search-blue.png';
import SearchPng from 'AssetPipelineImages/search.png';
import SupportBluePng from 'AssetPipelineImages/support-blue.png';
import SupportPng from 'AssetPipelineImages/support.png';
import UsersBluePng from 'AssetPipelineImages/users-blue.png';
import UsersPng from 'AssetPipelineImages/users.png';

const AdminPeopleControls = ({
  activePage
}) => {
  return (
    <>
      <div className={`controls-device-group ${ activePage === 'people_admin' ? 'active-group' : '' }`}>
        <div className="device-header clearfix">
          <div className="device-highlight"></div>
          <div className="device-icon">
            <a href="/admin/people/admins">
              {
                activePage === 'people_admin' ?
                  <img src={UsersBluePng} /> :
                  <img src={UsersPng} />
              }
              <div className="device-label-mobile">Admins</div>
            </a>
          </div>
          <div className="device-label"><a href="/admin/people/admins">Master Admin Setup</a></div>
        </div>
      </div>

      <div className={`controls-device-group ${ activePage === 'people_customers' ? 'active-group' : '' }`}>
        <div className="device-header clearfix">
          <div className="device-highlight"></div>
          <div className="device-icon">
            <a href="/admin/people/customers">
              {
                activePage === 'people_customers' ?
                  <img src={SearchBluePng} /> :
                  <img src={SearchPng} />
              }
              <div className="device-label-mobile">Customers</div>
            </a>
          </div>
          <div className="device-label"><a href="/admin/people/customers">Customer Lookup</a></div>
        </div>
      </div>

      <ControlsDeviceGroup
        activePage="service_desk"
        controlsDeviceGroupId="admin-service-desk"
        currentActivePage={activePage}
        iconStyle={{ marginTop: '15px' }}
        imageActive={SupportBluePng}
        imageInactive={SupportPng}
        link="/admin/service-desk/index"
        mobileTitleLabel="Service"
        titleLabel="Service Desk"
        subControls={[
          {
            key: 'service-desk-sub-control-new-case',
            subControlId: 'service-desk-sub-control-new-case',
            subControlLabel: 'new case',
            subControlUrl: '/admin/service-desk/new',
          },
        ]}
      />
    </>
  );
};

export default AdminPeopleControls;
