/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable';
import moment from 'moment-timezone'; // TODO: LEGACY_CODE Remove after legacy support ends.
import 'jquery';
import 'regenerator-runtime/runtime';

// Remember to prefix tailwindcss selectors with "tw-"
// Prefix and Tailwind settings are found in tailwind.config.js
require('stylesheets/tailwindcss.scss');
require('stylesheets/admin.scss');

window.moment = moment; // TODO: LEGACY_CODE Remove after legacy support ends.

// Load and start the rails_ujs script
try {
  require("@rails/ujs").start();
  global.Rails = Rails;
} catch (e) {
  // We ignore the error because somewhere in the code it starts rails_ujs
  // and results in an uncaught error like `Error: rails-ujs has already been loaded!`
  // TODO: Fix this when we migrate 100% away from asset pipeline
  // console.log(e);
}
// Support component names relative to this directory:
var componentRequireContext = require.context("src", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
