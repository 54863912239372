import React from 'react';
import ControlsDeviceSubControls from './controls-device-sub-controls';

const ControlsDeviceGroup = ({
  activePage,
  controlsDeviceGroupId,
  currentActivePage,
  mobileStyles,
  mobileTitleLabel,
  iconClass,
  iconStyle,
  iconToolTip,
  imageActive,
  imageInactive,
  link,
  titleLabel,
  titleLabelStyle,
  subControlWapperClass,
  subControls,
}) => {
    return (
        <>
          {mobileStyles && <style>{mobileStyles}</style>}

          <div
            id={controlsDeviceGroupId}
            className={`controls-device-group ${currentActivePage === activePage ? 'active-group' : ''}`}
          >
            <div className="device-header clearfix">
              <div className="device-highlight"></div>
              <div className="device-icon" data-toggle="tooltip" title={iconToolTip}>
                <a href={link}>
                  {
                    currentActivePage === activePage ?
                      <img src={imageActive} style={iconStyle} className={iconClass} /> :
                      <img src={imageInactive} style={iconStyle} className={iconClass} />
                  }

                  <div className="device-label-mobile">{mobileTitleLabel}</div>
                </a>
              </div>
              <div className="device-label"><a style={titleLabelStyle} href={link}>{titleLabel}</a></div>
            </div>
            {
              subControls && (
                <div
                  data-device-group={`${controlsDeviceGroupId}-group`}
                  className={`${subControlWapperClass || '' } reports-sub-controls device-sub-controls-wrapper ${currentActivePage !== activePage ? 'hidden': ''}`}
                  style={{ display: 'unset' }}
                >
                  {
                    subControls.map((subControlProps) => {
                      return <ControlsDeviceSubControls {...subControlProps} />
                    })
                  }
                </div>
              )
            }
          </div>
        </>
    );
};

export default ControlsDeviceGroup;
