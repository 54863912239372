ReactRailsUJS.detectEvents();

import React, { useEffect, useState } from 'react';

import axios from 'axios';
import DataGrid from 'react-data-grid';
import { DateTime } from "luxon";

import 'stylesheets/common';

const DATE_FORMAT = 'MMM. d, yyyy';

const columnsFactory = ({
  userPanelIsOpen,
  setUserPanelIsOpen,
  notifications,
}) => {
  const columns = [
    { key: 'id', name: 'ID', width: 20, },
    { key: 'email', name: 'Email' },
    {
      key: 'created_at',
      name: 'Signed up on',
      formatter: (props) => {
        if (props.row.created_at) {
          const createdAt = DateTime.fromISO(props.row.created_at);
          return <>{createdAt.toFormat(DATE_FORMAT)}</>;
        } else {
          return <>No Data</>
        }
      }
    },
    {
      key: 'last_login_at',
      name: 'Last sign in',
      formatter: (props) => {
        if (props.row.last_login_at) {
          const createdAt = DateTime.fromISO(props.row.last_login_at);
          return <>{createdAt.toFormat(DATE_FORMAT)}</>;
        } else {
          return <>Never Logged In</>
        }
      }
    },
    // {
    //   key: 'number_of_accounts',
    //   name: 'Number of accounts',
    // },
    // {
    //   key: 'started_at',
    //   name: "Last notified",
    //   formatter: (props) => {
    //     if (notifications[props.row.id]) {
    //       return (
    //         <div>
    //           {DateTime.fromISO(notifications[props.row.id][0].started_at).toFormat(DATE_FORMAT)}
    //         </div>
    //       );
    //     } else {
    //       return (<div>Never</div>);
    //     }
    //   }
    // },
    {
      key: 'actions',
      name: 'Actions',
      formatter: (props) => {
        const userProfilePath = `/admin/users/${props.row.id}`;

        const deleteUser = () => {
          axios({
            method: 'DELETE',
            url: userProfilePath,
            headers: {
              'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
            }
          })
          .then((response) => {
            location.reload();
          })
        }


        const userActions = [
          {
            key: 'user-actions-more-details',
            label: 'More Details',
            href: userProfilePath,
            rel: 'nofollow',
          },
          {
            key: 'user-actions-delete-user',
            label: 'Delete',
            href: '#',
            rel: 'nofollow',
            onClick: deleteUser,
          },
        ]

        return (
          <>
            { userActions.map((action) => {
              return (
                <a
                  key={action.key}
                  href={action.href}
                  rel={action.rel}
                  onClick={action.onClick}
                  className="m-4"
                >
                  {action.label}
                </a>
              );
            }) }
          </>
        )
      },
    }
  ];
  return columns;
}

const UsersIndex = ({
  activeUsers,
  notifications,
}) => {
  const [userPanelIsOpen, setUserPanelIsOpen] = useState();

  const [userId, setUserId] = useState();

  const columns = columnsFactory({
    userPanelIsOpen,
    setUserPanelIsOpen,
    notifications,
  });

  useEffect(() => {
    const url = new URL(document.location);
    const userId = url.searchParams.get('userId');

    // Open the panel if the userId is in the URL.
    if (userId && userId !== null) {
      setUserPanelIsOpen(true);
      setUserId(userId);
    }
  }, []);

  return (
    <div>
      <DataGrid
        className='rdg-light'
        columns={columns}
        rows={activeUsers}
        style={{ height: '1000px' }}
      />
    </div>
  );
};

export default UsersIndex;
